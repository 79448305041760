<template>
  <b-card>
    <tokens-list />
  </b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import TokensList from './TokensList.vue'

export default {
  components: {
    BCard,
    TokensList,
  },
}
</script>
