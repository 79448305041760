<template>
  <b-modal
    id="delete-token-popup"
    :visible="visible"
    size="md"
    ok-title="Удалить"
    cancel-title="Отменить"
    ok-variant="danger"
    :title="`Удалить токен «${token.name}»?`"
    @ok="clickOnSave"
    @hidden="$emit('hidden')"
  />
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Token from '@/api/http/models/token/Token'
import { mapGetters } from 'vuex'

export default {
  props: {
    token: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      visible: false,
      currentModel: {},
    }
  },
  computed: {
    ...mapGetters({
      currentSite: 'user/currentSite',
    }),
  },
  async created() {
    this.visible = true
  },
  methods: {
    async clickOnSave(modalEvent) {
      modalEvent.preventDefault()
      this.handleSubmit()
    },
    async handleSubmit() {
      const response = await Token.delete(this.currentSite.slug, this.token.id)

      if (response.status === 'success') {
        this.$emit('deleted', this.token.id)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Токен успешно удален',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
        this.$nextTick(() => {
          this.$bvModal.hide('delete-token-popup')
        })
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Ошибка при удалении токена',
            icon: 'AlertCircleIcon',
            variant: 'danger',
          },
        })
      }
    },
  },
}
</script>
