<template>
  <b-modal
    id="add-token-popup"
    :visible="visible"
    size="md"
    :ok-title="okTitle"
    cancel-title="Отменить"
    @ok="clickOnSave"
    @hidden="$emit('hidden')"
  >
    <b-card-title
      title-tag="h2"
    >
      {{ popupTitle }}
    </b-card-title>
    <form
      ref="form"
      @submit.stop.prevent="handleSubmit"
    >
      <b-form-group
        label="Название токена"
        label-for="token_name"
      >
        <b-form-input
          id="token_name"
          v-model="tokenName"
          v-validate="'required'"
          name="token_name"
          :disabled="hasToken"
        />
        <small
          v-show="errors.has('token_name')"
          class="text-danger"
        >{{ errors.first('token_name') }}</small>
      </b-form-group>
      <div
        v-if="hasToken"
        class="mt-3"
      >
        <b-card-title
          title-tag="h4"
          class="mb-1"
        >
          Не забудьте скопировать токен
        </b-card-title>
        <b-card-text class="text-warning">
          В будущем сгенерированный токен не будет доступен<br>для чтения и копирования
        </b-card-text>
        <b-input-group>
          <b-form-input
            id="token_hash"
            ref="tokenHash"
            :value="token.token"
            name="token_hash"
            disabled
          />
          <b-input-group-append>
            <b-button
              variant="primary"
              @click.prevent="onCopy"
            >
              <feather-icon
                icon="CopyIcon"
              />
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </div>
    </form>
  </b-modal>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BCardTitle, BFormGroup, BFormInput, BInputGroup, BInputGroupAppend, BButton, BCardText,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import Token from '@/api/http/models/token/Token'

export default {
  name: 'AddTokenPopup',
  components: {
    BCardTitle,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BButton,
    BCardText,
  },
  data() {
    return {
      visible: false,
      token: {},
      tokenName: '',
      okTitle: 'Создать',
      popupTitle: 'Создание нового токена',
    }
  },
  computed: {
    ...mapGetters({
      currentSite: 'user/currentSite',
    }),
    hasToken() {
      return (this.token?.token || '') !== ''
    },
  },
  created() {
    this.visible = true
  },
  methods: {
    async clickOnSave(modalEvent) {
      modalEvent.preventDefault()

      if (this.token.token) {
        this.$nextTick(() => {
          this.$bvModal.hide('add-token-popup')
        })

        return
      }

      this.handleSubmit()
    },
    async handleSubmit() {
      const validate = await this.$validator.validateAll()

      if (validate) {
        const response = await Token.create(this.currentSite.slug, {
          token_name: this.tokenName,
        })

        if (response.status === 'success') {
          this.token = response.data
          this.$emit('added', this.token)
          this.okTitle = 'Ок'
          this.popupTitle = 'Токен сгенерирован'
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Токен создан',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Ошибка при  создании токена',
              icon: 'AlertCircleIcon',
              variant: 'danger',
            },
          })
        }
      }
    },
    onCopy() {
      this.$refs.tokenHash.$el.disabled = false
      this.$refs.tokenHash.$el.select()
      this.$refs.tokenHash.$el.setSelectionRange(0, 99999)
      document.execCommand('copy')
      this.$refs.tokenHash.$el.disabled = true
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Токен скопирован в буфер обмена',
          icon: 'CheckIcon',
          variant: 'success',
        },
      })
    },
  },
}
</script>
