<template>
  <div>
    <div v-if="tokens.length">
      <div class="accordion-header">
        <div class="accordion-label__content">
          <div class="row align-items-center w-100">
            <div class="col-12 col-lg-4">
              Название
            </div>
            <div class="col-12 col-lg-3">
              Создан
            </div>
            <div class="col-12 col-lg-3">
              Последний раз использовался
            </div>
          </div>
        </div>
      </div>
      <div class="accordion">
        <div
          v-for="token in tokens"
          :key="token.id"
          class="accordion__item"
        >
          <div class="accordion-label accordion-label--disable-arrow">
            <div class="accordion-label__content">
              <div class="row w-100 align-items-center">
                <div class="col-12 col-lg-4">
                  {{ token.name }}
                </div>
                <div class="col-12 col-lg-3">
                  <span v-if="token.created_at">
                    {{ relativeTime(token.created_at) }}
                  </span>
                  <span v-else>
                    N/A
                  </span>
                </div>
                <div class="col-12 col-lg-3">
                  <span v-if="token.last_used_at">
                    {{ relativeTime(token.last_used_at) }}
                  </span>
                  <span v-else>
                    никогда
                  </span>
                </div>
                <div class="col-12 col-lg-2">
                  <div class="d-flex justify-content-end align-items-center">
                    <b-button
                      v-b-tooltip.hover.left="'Удалить'"
                      variant="link"
                      size="sm"
                      @click.stop="onDelete(token)"
                    >
                      <feather-icon
                        icon="Trash2Icon"
                        size="18"
                      />
                    </b-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      У вас еще нет ни одного токена
    </div>
    <b-button
      class="mt-2"
      variant="primary"
      @click.prevent="showAddPopup = true"
    >
      Создать новый токен
    </b-button>
    <add-token-popup
      v-if="showAddPopup"
      @hidden="showAddPopup = false"
      @added="addToken"
    />
    <delete-token-popup
      v-if="showDeletePopup"
      :key="deletingToken.id"
      :token="deletingToken"
      @hidden="showDeletePopup = false"
      @deleted="deleteToken"
    />
  </div>
</template>

<script>
import { BButton, VBTooltip } from 'bootstrap-vue'
import { mapGetters } from 'vuex'

import moment from 'moment'

import Token from '@/api/http/models/token/Token'

import AddTokenPopup from './AddTokenPopup.vue'
import DeleteTokenPopup from './DeleteTokenPopup.vue'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BButton,
    AddTokenPopup,
    DeleteTokenPopup,
  },
  data() {
    return {
      showDeletePopup: false,
      showAddPopup: false,
      deletingToken: {},
      tokens: [],
    }
  },
  computed: {
    ...mapGetters({
      currentSite: 'user/currentSite',
    }),
  },
  async created() {
    const response = await Token.getAll(this.currentSite.slug)

    this.tokens = response.data.length ? response.data : []
  },
  methods: {
    addToken(token) {
      this.tokens.push(token)
    },
    deleteToken(tokenId) {
      const index = this.tokens.findIndex(token => token.id === tokenId)

      if (index !== -1) {
        this.tokens.splice(index, 1)
      }
    },
    relativeTime(timestamp) {
      return moment(timestamp).fromNow()
    },
    onDelete(token) {
      this.deletingToken = token
      this.showDeletePopup = true
    },
  },
}
</script>
